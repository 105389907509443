import { LoginService } from '../../pages/login/providers/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router:Router,
    private loginService: LoginService
    ){}
    
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      let adminTrue = await this.loginService.isAdmin()
      if (adminTrue) {
        return true
      }else{
        this.router.navigate([''])
        return false
      }
  }
}
