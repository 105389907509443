import { UsersComponent } from './pages/users/components/users.component';
import { HomeComponent } from './pages/home/components/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/components/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';

export const routes: Routes = [
  {
    path:'',
    component: HomeComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'usuarios',
    component: UsersComponent,
    canActivate:[AdminGuard]
  },
  {
    path:'',
    children:[
      {path:'', redirectTo:'login',pathMatch:'full'},
      {path:'login', component:LoginComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
