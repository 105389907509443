import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  async Conectar(user: any) {
    const conectar = await this.http.post<any>(`${environment.api}/conectar`, { user }).toPromise()
    return conectar

  }

  async Desconectar(user: any) {
    const desconectar = await this.http.post<any>(`${environment.api}/desconectar`, { user }).toPromise()
    return desconectar

  }

  async gerarQR(user: string) {
    const recarregar = await this.http.post<any>(`${environment.api}/QRcode`, { user }).toPromise()
    return recarregar

  }

  async verificarConexao(user: string) {
    const verificarConexao = await this.http.post<any>(`${environment.api}/verificarConexao`, { user }).toPromise()
    return verificarConexao

  }

  async Sender(message: any, user: string, baseSelecionada:string, timer:string) {
    const sender = await this.http.post<any>(`${environment.api}/send`, { message, user, baseSelecionada, timer }).toPromise()
    return sender
  }

  async Importar(user: string, nameFile: string) {
    const importacao = await this.http.post<any>(`${environment.api}/importacao`, { user, nameFile }).toPromise()
    return importacao
  }

  async ListarBases(user: string) {
    const listaBases = await this.http.post<any>(`${environment.api}/listarBasesAtivas`, { user }).toPromise()
    return listaBases
  }

  async InativarBase(user: string, campaign: string) {
    const inativaBase = await this.http.post<any>(`${environment.api}/inativarBase`, { user, campaign }).toPromise()
    return inativaBase
  }

  async SobreBase(user: string, campaign: string) {
    const sobreBase = await this.http.post<any>(`${environment.api}/sobreBase`, { user, campaign }).toPromise()
    return sobreBase
  }
  

  async Base(files: Set<File>) {
    const user = window.sessionStorage.getItem('usuario')
    let i = {headers:  {
      'user': user
  }}
    const formData = new FormData()
    files.forEach(file => {
      formData.append('file', file, file.name)
    })

    const sobreBase = await this.http.post<any>(`${environment.api}/importacao`, formData,i).toPromise()
    return sobreBase
  }

  async ListaUsuarios(paginator: any, id?: number) {
    const lista = await this.http.post<any>(`${environment.api}/listaUsuario`, { paginator, id }).toPromise()
    return lista
  }

  async AlterarUsuario(usuario: { id: string; name_full: string; username: string; password: string; }) {
    const lista = await this.http.post<any>(`${environment.api}/alterarUsuario`, { usuario }).toPromise()
    return lista
  }

  async CriarUsuario(usuario: { id: string; name_full: string; username: string; password: string; }) {
    const lista = await this.http.post<any>(`${environment.api}/criarUsuario`, { usuario }).toPromise()
    return lista
  }

  async DeletarUsuario(id: number) {
    const deleta = await this.http.post<any>(`${environment.api}/deletarUsuario`, { id }).toPromise()
    return deleta
  }


}
