import { UsersService } from './../providers/users.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  public loading = false
  public listar = true
  public adicionar = false
  public editar = false

  public error: any
  public success: any
  public usuarios: any
  public msgLoading: string

  public paginator = {
    page: 0,
    size: 10
  }
  public usuario = {
    id: "",
    name_full: "",
    username: "",
    password: ""
  }

  constructor(
    private router: Router,
    private usersService: UsersService
  ) { }


  async ngOnInit() {
    try {
      this.loading = true
      this.msgLoading = "Buscando usuarios..."
      this.usuarios = await this.usersService.ListaUsuarios(this.paginator)
      this.loading = false

    } catch (error) {
      this.loading = false
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'



    }

  }

  // CRUD USUARIO
  Adicionar() {
    this.listar = false
    this.adicionar = true
  }

  Cancelar() {
    this.listar = true
    this.adicionar = false
    this.editar = false
    this.usuario.id = ""

  }

  async Editar(id: number) {
    try {
      this.loading = true
      this.msgLoading = "Carregando..."
      this.listar = false
      this.adicionar = false
      this.editar = true
      let usuario = await this.usersService.ListaUsuarios(this.paginator, id)
      this.usuario.id = usuario[0].id
      this.usuario.name_full = usuario[0].name_full
      this.usuario.username = usuario[0].username
      this.usuario.password = usuario[0].password
      this.loading = false


    } catch (error) {
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'

    }
  }

  async validacao() {
    try {
      if (this.usuario.name_full == '' || this.usuario.username == '' || this.usuario.password == '') {
        this.error = 'ERROR: Todos os campos devem sem devidamente preenchidos.'
        location.href = '#error-modal'
        return false
      }

      else {
        return true
      }

    } catch (error) {
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'

    }


  }

  async Salvar(instancia: string) {

    try {
      this.loading = true
      this.msgLoading = "Salvando alterações..."
      this.usuario.name_full = (<HTMLInputElement>document.getElementById('name_full')).value
      this.usuario.username = (<HTMLInputElement>document.getElementById('username')).value
      this.usuario.password = (<HTMLInputElement>document.getElementById('password')).value

      let validar = await this.validacao()
      if (validar) {

        if (instancia === 'editar') {
          this.usuario.id = (<HTMLInputElement>document.getElementById('id')).value
          let editar = await this.usersService.AlterarUsuario(this.usuario)
        } else if (instancia === 'adicionar') {
          let editar = await this.usersService.CriarUsuario(this.usuario)

        }
        this.usuarios = await this.usersService.ListaUsuarios(this.paginator)
        this.listar = true
        this.adicionar = false
        this.editar = false
        this.loading = false

      } else {
        this.loading = false
        this.error = 'Erro ao tentar salvar.'
        location.href = '#error-modal'

      }

    } catch (error) {
      this.loading = false
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'

    }




  }

  async Deletar(id: number) {
    try {
      this.loading = true
      this.msgLoading = "Excluindo usuarios..."
      let usuario = await this.usersService.DeletarUsuario(id)
      this.usuarios = await this.usersService.ListaUsuarios(this.paginator)
      this.loading = false
      this.listar = true
      this.adicionar = false
      this.editar = false

    } catch (error) {
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'
    }

  }


 


}
