import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) { }

  async ListaUsuarios(paginator: any, id?: number) {
    const lista = await this.http.post<any>(`${environment.api}/listaUsuario`, { paginator, id }).toPromise()
    return lista
  }

  async AlterarUsuario(usuario: { id: string; name_full: string; username: string; password: string; }) {
    const alterado = await this.http.post<any>(`${environment.api}/alterarUsuario`, { usuario }).toPromise()
    return alterado
  }

  async CriarUsuario(usuario: { id: string; name_full: string; username: string; password: string; }) {
    const criado = await this.http.post<any>(`${environment.api}/criarUsuario`, { usuario }).toPromise()
    return criado
  }

  async DeletarUsuario(id: number) {
    const deletado = await this.http.post<any>(`${environment.api}/deletarUsuario`, { id }).toPromise()
    return deletado
  }


}
