import { Router } from '@angular/router';
import { LoginService } from './../providers/login.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {
  public loading: boolean

  public error = false
  public msgError: any
  public user = {
    username: '',
    password: ''
  }

  constructor(
    private loginService: LoginService,
    private router: Router

  ) { }

  ngOnInit() {
    try {
      localStorage.clear();
      sessionStorage.clear()
      const el = (<HTMLInputElement>document.getElementById('tab-1'))
      this.ativarCheckbox(el)



    } catch (error) {
      this.error = true
      this.msgError = "Não foi possivel logar, tente novamente daqui alguns instantes"
    }
  }

  onKeydown(event: { key: string; }) {
    if (event.key === "Enter") {
      this.login();
    }
  }

  ativarCheckbox(el: { checked: boolean; }) {
    el.checked = true;
  }


  async login() {
    try {
      this.loading = true
      this.user.username = (<HTMLInputElement>document.getElementById('username')).value
      this.user.password = (<HTMLInputElement>document.getElementById('password')).value
      if (this.user.username === '' || this.user.password === '') {
        this.error = true
        this.loading = false
        this.msgError = 'Preenchar os campos de usuario e senha corretamente'
      } else {
        const logar = await this.loginService.login(this.user)
        if (!logar) {
          this.error = true
         this.msgError =  this.loginService.error
        }
        this.loading = false
        this.router.navigate([''])
      }

    } catch (error) {
      this.loading = false
      this.error = true
      this.msgError = "Não foi possivel logar, tente novamente daqui alguns instantes"
    }
  }



}
