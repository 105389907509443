import { Router } from '@angular/router';
import { routes } from 'src/app/app-routing.module';
import { LoginService } from './../../pages/login/providers/login.service';
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs"
import { catchError } from "rxjs/operators"


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    constructor(
        private loginService:LoginService,
        private router:Router,
    ){}


    intercept(req: HttpRequest<any>, next: HttpHandler){
        const token =this.loginService.getAuthToken()
        let request:HttpRequest<any> = req

        if (token && !this.loginService.isTokenExpired(token)) {
            request = req.clone({
                headers:req.headers.set('Authorization',`Bearer ${token}`)
            })
        }

        return next.handle(request).pipe(
            catchError((err:HttpErrorResponse)=>{
                let  erroMsg = ''

                if (err.error instanceof ErrorEvent) {
                    erroMsg = err.error.message
                }else{
                    erroMsg = `status Code ${err.status} message ${err.error}  `
                }
                return throwError(()=> new Error(erroMsg))
            })
        )
    }


    public HandleError(err: HttpErrorResponse){
        if (err.error instanceof ErrorEvent) {
            let erroMsg = ''

        }else{
                if (err.error.msg == "token invalido") {
                    localStorage.clear();
                    sessionStorage.clear()
                    location.reload()
                }
/*       habilitar esse comando apos desenvolvimento por questos de segurança
 */              console.error(
                `Erro ${JSON.stringify(err.error.msg)}`
                ) 
            
             
             

        }

                    

        return throwError(err.error)
    }
    

    
    
}


