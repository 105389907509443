import { LoginService } from './../../login/providers/login.service';
import { HomeService } from './../providers/home.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public files: Set<File>
  public bloqButton = true
  public loading = false
  public img = false
  public conectado: boolean

  public adm: boolean
  public nome: any
  public error: any
  public success: any
  public dataQR: any
  public usuarios: any
  public bases: any
  public qtdsRegistros: any
  public msgLoading: string

  public paginator = {
    page: 0,
    size: 10
  }


  constructor(
    private homeService: HomeService,
    private loginService: LoginService,
    private router: Router,
  ) { }


  async ngOnInit() {
    try {
      const user = window.sessionStorage.getItem('usuario')
      this.nome = window.sessionStorage.getItem('nome')
      this.adm = await this.loginService.isAdmin()
      this.router.navigate([''])
      this.loading = true
      this.msgLoading = "Verificando conexão..."
      this.bases = await this.homeService.ListarBases(user)
      this.bases = this.bases.bases
      if (!this.adm) {
        this.conectado = await this.homeService.verificarConexao(user)
        if (this.conectado) {
          this.msgLoading = "STATUS: CONECTADO"
        } else {
          this.msgLoading = "STATUS: DESCONECTADO"
        }
        setTimeout(() => {
          this.loading = false
        }, 3000);
      }else{
        this.usuarios = await this.homeService.ListaUsuarios(this.paginator)

      }
      this.loading = false


    } catch (error) {
      this.loading = false
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'



    }

  }


  async deslogar() {
    this.router.navigate(['login'])
  }


  async conectar() {
    const user = window.sessionStorage.getItem('usuario')
    try {
      this.loading = true
      this.msgLoading = "Conectando..."
      this.bloqButton = false
      this.dataQR = this.homeService.Conectar(user)
        .then(async () => {
          this.loading = true
          this.msgLoading = "Verificando conexão..."
          this.conectado = await this.homeService.verificarConexao(user)
          this.img = false
          this.loading = false
          this.bloqButton = true

        })
    } catch (error) {
      this.loading = false
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'


    }
    this.loading = true
    this.msgLoading = "Gerando QR-CODE..."
    this.dataQR = this.homeService.gerarQR(user).then(async (e) => {
      this.img = true
      this.dataQR = e
      this.loading = false

    })

  }

  async disconnect() {
    try {
      const user = window.sessionStorage.getItem('usuario')
      this.loading = true
      this.msgLoading = "Desconectando..."
      await this.homeService.Desconectar(user)
      this.conectado = await this.homeService.verificarConexao(user)
      this.loading = false
      this.img = false
      this.bloqButton = true

    } catch (error) {
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'

    }

  }

  async chamarQR() {
    try {
      const user = window.sessionStorage.getItem('usuario')
      this.loading = true
      this.msgLoading = "Gerando QR-CODE..."
      this.dataQR = await this.homeService.gerarQR(user)
      this.loading = false

    } catch (error) {
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'

    }

  }

  async Sender() {
    try {
      this.loading = true
      this.msgLoading = "Realizando disparos..."
      let user = window.sessionStorage.getItem('usuario')
      let baseSelecionada = (<HTMLSelectElement>document.getElementById('select-campanha')).value
      let messageSend = (<HTMLInputElement>document.getElementById('message')).value
      let timer = (<HTMLInputElement>document.getElementById('timer')).value
      if(timer > '40'){
        this.loading = false
        this.error = 'O limite maximo definido é de 40 segundos'
        location.href = '#error-modal'
        return this.error = 'O limite maximo definido e de 40 segundos'

      }
      if (messageSend == '' || user == '' || baseSelecionada == '' || timer == '') {
        if (user == '') {
          this.loading = false
          this.error = 'Não e possivel realizar o disparo, tente deslogar e logar novamente'
          location.href = '#error-modal'
          return this.error = 'Não e possivel realizar o disparo, tente deslogar e logar novamente'
          
        } else {
          this.loading = false
          this.error = 'Não e possivel enviar mensagens em branco, sem campanha ou sem intervalo'
          location.href = '#error-modal'
          return this.error
          
        }
      } else {
        timer = timer + '000'
        await this.homeService.Sender(messageSend, user, baseSelecionada, timer).then(async (success) => {
          await this.ListarBases()
          this.success = success
          this.loading = false
          location.href = '#success-modal'

        })

      }

    } catch (error) {
      this.loading = false
      this.error = 'Erro na comunicação com o servidor, por favor tente novamente daqui alguns instantes.'
      location.href = '#error-modal'
    }
  }

  MenuImportacao() {
    (<HTMLInputElement>document.getElementById('customFileLabel')).value = ""
  }

  SelectedFile(event: { srcElement: { files: FileList; }; }) {
    const selectedFiles = <FileList>event.srcElement.files
    const fileNames = []
    this.files = new Set()
    for (let i = 0; i < selectedFiles.length; i++) {
      fileNames.push(selectedFiles[i].name)
      this.files.add(selectedFiles[i])
    }
    document.getElementById('customFileLabel').innerHTML = fileNames.join(', ')

  }

  async Importacao() {
    const user = window.sessionStorage.getItem('usuario')
    location.href = '#'
    this.loading = true
    this.msgLoading = "Importando base..."
    let returning = await this.homeService.Base(this.files)
    await this.ListarBases()
    if (returning.success) {
      this.success = returning.success
      this.loading = false
      location.href = '#success-modal'
    } else {
      this.error = returning.error
      this.loading = false
      location.href = '#error-modal'
    }


  }

  async ListarBases() {
    try {
      const user = window.sessionStorage.getItem('usuario')
      this.qtdsRegistros = ''

      if (user == '') {
        this.error = 'Tente deslogar e logar.'
        location.href = '#error-modal'
      } else {
        this.loading = true
        this.msgLoading = "Buscando lista de campanhas..."
        this.bases = await this.homeService.ListarBases(user)
        this.bases = this.bases.bases
        this.loading = false

      }

    } catch (error) {
      this.loading = false
    }
  }

  async InativarBase() {
    try {
      let baseSelecionada = (<HTMLSelectElement>document.getElementById('campanha')).value
      this.loading = true
      this.msgLoading = "Inativando base " + baseSelecionada + " ..."
      location.href = '#'
      const user = window.sessionStorage.getItem('usuario')
      this.success = await this.homeService.InativarBase(user, baseSelecionada)
      this.success = this.success.success
      await this.ListarBases()
      this.loading = false
      location.href = '#success-modal'
    } catch (error) {

    }
  }

  async SobreBase() {
    this.loading = true
    this.msgLoading = "Buscando dados sobre a base..."
    const user = window.sessionStorage.getItem('usuario')
    let baseSelecionada = (<HTMLSelectElement>document.getElementById('campanha')).value
    if (baseSelecionada === "Selecione...") {
      this.loading = false
      this.qtdsRegistros = 'Selecione uma campanha'

    } else {
      this.qtdsRegistros = await this.homeService.SobreBase(user, baseSelecionada)
      this.loading = false
      this.qtdsRegistros = this.qtdsRegistros.count + ' registros ativos referente a essa campanha'

    }
  }




}
