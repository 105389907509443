import { environment } from './../../../../environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwtDecode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public hash = "bjsudbfisabdifuwsdnjcu9eiddihbprjegnf90pjvpsodcuplçwadn";
  public error: string


  constructor(private http: HttpClient) { }



  async login(user: any) {
    const login = await this.http.post<any>(`${environment.api}/login`, { user }).toPromise()
    if (login && login.token && login.id) {
      window.sessionStorage.setItem('usuario', login.id)
      window.sessionStorage.setItem(this.hash, login.token)
      window.sessionStorage.setItem('nome', login.nome)

      return true
    }
    this.error = login.error
    return false
  }

  getAuthToken() {
    const token: any = window.sessionStorage.getItem(this.hash)
    return token


  }



  isTokenExpiredDate(token?: string): boolean {
    const decode: any = jwtDecode(token)
    if (decode === '') {
      return null
    }

    const date: any = decode.id
    window.sessionStorage.setItem('usuario', date)
    return date
  }




  isTokenExpired(token?: string): boolean {
    if (!token) {
      return true
    }
    // const date: any = this.isTokenExpiredDate(token)
    // const veryId = window.sessionStorage.getItem('hash')


    // if (date !== veryId) {
    //   return true
    // }


    return false
  }


  isUserLoggedin() {
    const token = this.getAuthToken()
    if (!token) {
      return false
    } else if (this.isTokenExpired(token)) {
      return false
    }
    return true
  }

  async isAdmin() {
    const logado = this.isUserLoggedin()
    if (logado) {
      const admin = await this.http.post<any>(`${environment.api}/isAdmin`, {}).toPromise()
      if (admin) {
        return true
      }
      return false
    }

    return false

  }





}